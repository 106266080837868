<template>
  <div class="home">
    <Card style="width: 100%" shadow="none" dis-hover>
      <template #title> 旺店通 </template>
      <template #extra v-if="!ifBind">
        <span @click="showBindForm">点击绑定分销商 ></span>
      </template>
      <div v-if="!ifBind && !ifShowBind">当前暂未绑定分销商</div>
      <div v-else-if="ifBind">
        <Row class="bind-info">
          <!-- <Col span="12">
            <div class="title">{{ userInfo.title }}</div>
            <div class="desc">等级</div>
          </Col> -->
          <Col span="24">
          <div class="title">¥{{ userInfo.money }}</div>
          <div class="desc">年度金额</div>
          </Col>
        </Row>
      </div>
      <div v-if="ifShowBind">
        <Form v-if="!ifShowConfirm" ref="formInline" :label-width="80" :model="bindForm" :rules="ruleForm" inline>
          <FormItem prop="business_id" label="客户编号">
            <Input style="width: 130px" type="text" v-model="bindForm.business_id" placeholder="请输入客户编号" />
            <Button type="text" @click="submitBind('formInline')">
              绑定
            </Button>
            <Button type="text" @click="cancelBind"> 取消 </Button>
          </FormItem>
        </Form>
        <Alert v-if="ifShowConfirm" type="warning">
          确定要绑定客户编号{{ bindForm.business_id }}吗？
          <template #desc>
            <Button size="small" type="primary" @click="confirmBind">
              确定
            </Button>
            <Button size="small" type="text" @click="ifShowConfirm = false">
              返回
            </Button>
          </template>
        </Alert>
      </div>
    </Card>
  </div>
</template>

<script>
import { BindApi, GetInfoApi, GetMoney } from '@/apis/bindApis'

export default {
  name: 'HomeView',
  components: {},
  costMoney: 0.00,
  data() {
    return {
      ifBind: false,
      ifShowBind: false,
      ifShowConfirm: false,
      bindForm: {
        business_id: '',
        external_userid: '',
        business_system: '旺店通/wdt',
      },
      ruleForm: {
        business_id: [
          {
            required: true,
            message: '请输入客户编号',
            trigger: 'blur',
          },
        ],
      },
      userInfo: {
        title: '金牌分销商',
        money: '0.00',
      },
    }
  },
  created() {
    if (this.$route.query.hasOwnProperty('userinfo_code')) {
      localStorage.setItem('userinfo_code', this.$route.query.userinfo_code)

      this.getUserInfo()
    } else {
      this.$Message.error('缺少用户信息，请检查')
    }
  },

  methods: {
    costMoney() {
      try {
        GetMoney({
          external_userid: this.userInfo.external_userid,
        }).then((res) => {
          if (res.data) {
            this.userInfo.money = res.data
          }
        })

      } catch (error) {
        console.log(error)
      }
    },
    showBindForm() {
      this.ifShowBind = true
    },
    submitBind(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ifShowConfirm = true
        } else {
          this.$Message.error('请输入客户编号!')
        }
      })
    },
    cancelBind() {
      this.bindForm.business_id = ''
      this.ifShowBind = false
    },
    async confirmBind() {
      try {
        let res = await BindApi(this.bindForm)
        console.log(res)
        this.$Message.success('绑定成功')
        //刷新页面 window
        window.location.reload()
      } catch (error) {
        console.log(error)
      }
    },
    async getUserInfo() {
      try {
        let infoRes = await GetInfoApi({
          userinfo_code: this.$route.query.userinfo_code,
        })
        console.log('infoRes', infoRes)
        if (infoRes.data.hasOwnProperty('business_data')) {
          this.userInfo = infoRes.data
          this.bindForm.external_userid = this.userInfo.external_userid
          if (infoRes.data.business_data.length) {
            this.ifBind = true
            this.costMoney()
          } else {
            this.ifBind = false
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.bind-info {
  text-align: center;

  .title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
  }

  .desc {
    font-size: 12px;
    color: #666;
  }
}
</style>
