import axios from '@/utils/api.request'

// 绑定
export const BindApi = (data) => {
  return axios.request({
    url: '/home/index/register',
    method: 'post',
    data,
  })
}

// 获取信息
export const GetInfoApi = (params) => {
  return axios.request({
    url: '/home/index/userInfo',
    method: 'get',
    params,
  })
}
//获取年度金额
// 获取信息
export const GetMoney = (params) => {
  return axios.request({
    url: '/home/index/statisticalAmount',
    method: 'get',
    params,
  })
}