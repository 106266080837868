import { createRouter, createWebHistory } from 'vue-router'
import Bind from '@/views/Bind.vue'

const routes = [
  {
    path: '/bind',
    name: 'bind',
    component: Bind,
  },
  {
    path: '/order-list',
    name: 'orderList',
    component: () => import('@/views/OrderList.vue'),
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
