import axios from 'axios'
import store from '@/store'
import { Message, LoadingBar } from 'view-ui-plus'

class HttpRequest {
  path = ''
  curPath = ''

  constructor(baseURL) {
    this.baseUrl = baseURL
    this.queue = {}
  }

  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    return config
  }

  destroy(url) {
    delete this.queue[url]
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }

  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      (config) => {
        if (process.env.VUE_APP_TYPE === 'app') {
          config.baseURL = store.getters.baseUrl
        }
        this.queue[url] = true
        // LoadingBar.start()
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    // 响应拦截
    instance.interceptors.response.use(
      (response) => {
        console.log('接口', url + ':', response.data)
        this.destroy(url)
        if (response.data.code === 0) {
          // LoadingBar.finish()
          return response.data
        } else if (response.data.code === 401) {
          // LoadingBar.error()
          Message.warning({
            duration: 5,
            closable: true,
            content: response.data.msg,
          })
          return Promise.reject(response.data)
        } else {
          // LoadingBar.error()
          Message.error({
            duration: 5,
            closable: true,
            content: response.data.msg,
          })
          return Promise.reject(response.data)
        }
      },
      (error) => {
        this.destroy(url)
        let errorInfo = error.response
        if (!errorInfo) {
          const {
            request: { statusText, status },
            config,
          } = JSON.parse(JSON.stringify(error))
          errorInfo = {
            statusText,
            status,
            request: {
              responseURL: config.url,
            },
          }
        }
        Message.error({
          duration: 5,
          closable: true,
          content: errorInfo.statusText,
        })
        // LoadingBar.error()
        return Promise.reject(error)
      }
    )
  }

  setPath(...paths) {
    this.curPath = `${this.path}${paths.join('/')}`
    return this
  }

  replace(...params) {
    let count = 0
    this.curPath = this.curPath.replace(/\{.*?\}/g, (_match) => params[count++])
    return this
  }

  request(options) {
    const instance = axios.create()
    options = Object.assign(this.getInsideConfig(), options)
    if (this.curPath !== '') {
      options.url = this.curPath
      this.curPath = ''
      this.path = ''
    }
    this.interceptors(instance, options.url)
    return instance(options)
  }
}

export default HttpRequest
